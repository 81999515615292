import React from 'react';

import TabSection from './tab';

const Tabs = ({ tabs, active, setActive, children }) => {
  return (
    <>
      <div>
        <TabSection tabs={tabs} active={active} setActive={setActive} />
        <div
          style={{
            marginTop: '20px',
          }}
        >
          {children && children}
        </div>
      </div>
    </>
  );
};

export default Tabs;
