import React, { useEffect, useState } from 'react';

import Tabs from 'components/tabs';
import Layout from 'components/layout';
import JobListing from './job-listing';

import style from './job.module.scss';

const Jobs = () => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    const handlePopState = () => {
      const currentURL = window.location.href;
      if (currentURL.endsWith('/job-listing')) {
        setActive(1);
      } else if (currentURL.endsWith('/applicants')) {
        setActive(2);
      } else {
        setActive(0);
      }
    };
    handlePopState();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleTabChange = (index) => {
    setActive(index);
    if (index === 1) {
      window.history.pushState(null, '', `/jobs/job-listing`);
    } else if (index === 2) {
      window.history.pushState(null, '', `/jobs/applicants`);
    } else {
      window.history.pushState(null, '', `/jobs`);
    }
  };

  return (
    <>
      <Layout>
        <div className={style.mainClass}>
          <h1 className={style.h1}>Jobs</h1>
          <div className={style.tabClass}>
            <Tabs
              tabs={tabs}
              active={active}
              setActive={handleTabChange}
              children={tabs[active].children}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Jobs;

const tabs = [
  { name: 'Dashboard', children: <div>Dashboard</div> },
  {
    name: 'Jobs Listing',
    children: <JobListing />,
  },
  { name: 'Applicants', children: <div>Applicants</div> },
];
