import { apiRequest } from 'utils/helper';

export const getAllReports = async ({
  params,
  setCount,
  setLoader,
  setReports,
  setPageLoader,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: '/job-reporting/get-reports',
    params,
  });
  if (res?.status === 200) {
    setReports(res?.data?.reports);
    setCount(res?.data?.count);
    setLoader(false);
    setPageLoader(false);
  }
};
