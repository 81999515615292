import React from 'react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';

import { store } from 'redux-store';

function App() {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <Routes />
          <ToastContainer />
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
