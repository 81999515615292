import { apiRequest } from '../utils/helper';

export const getAllJobs = async ({
  jobs,
  params,
  setJobs,
  setLoader,
  pageLoader,
  setJobsCount,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: '/job/position',
    params,
  });
  if (res?.status === 200) {
    setJobsCount(res?.data?.jobsCount);
    pageLoader
      ? setJobs([...jobs, ...res?.data?.jobs])
      : setJobs(res?.data?.jobs);
    setLoader(false);
  }
};

export const getJobApplicants = async ({
  id,
  params,
  setData,
  setLoader,
  pageLoader,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: `job/allAppliedCandidates/${id}`,
    params,
  });
  if (res?.status === 200) {
    pageLoader
      ? setData((prev) => ({
          ...prev,
          candidates: [...prev?.candidates, ...res?.data?.candidates],
        }))
      : setData(res?.data);
    setLoader(false);
  }
};

export const getJob = async ({ id, setJob }) => {
  const res = await apiRequest({
    type: 'get',
    path: `job/position/${id}`,
  });
  if (res?.status === 200) {
    return setJob(res?.data);
  }
};

export const deleteJob = async ({ id }) => {
  const res = await apiRequest({
    type: 'delete',
    path: `/job/delete-job/${id}`,
  });
  if (res?.status === 200) {
    return res?.data;
  }
};

export const removeApplication = async ({ id }) => {
  const res = await apiRequest({
    type: 'delete',
    path: `job/removeApplication/${id}`,
  });
  if (res?.status === 200) {
    return res?.data;
  }
};
