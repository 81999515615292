import React from 'react';

import Modal from 'components/modal';
import Button from 'components/button';

import del from 'assets/applicant.svg';
import style from './del.module.scss';

const RemoveModal = ({ open, setOpen, delItem, delIcon, handleDelete }) => {
  return (
    <div>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        className={style.modal}
      >
        <div className={style.img}>
          <img src={delIcon || del} alt="" />
        </div>
        <p>Are you sure you want to remove this {delItem}? </p>
        <div className={style.btnDiv}>
          <Button text={'Remove'} handleClick={() => handleDelete()} />
          <Button
            text={'Cancel'}
            btnClass={style.btn}
            handleClick={() => setOpen(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RemoveModal;
