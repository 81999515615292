import React from 'react';

import style from './modal.module.scss';

const Modal = ({ open, children, className, handleClose, title }) => {
  const handleClickWrapper = () => {
    handleClose?.();
  };

  return (
    <>
      {open && (
        <div
          className={style.modalWrapper}
          onMouseDown={(e) => handleClickWrapper(e)}
        >
          <div
            className={`${style.modalContentWrapper} ${className}`}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {title && (
              <div className={style.header}>
                <p>{title}</p>
              </div>
            )}
            <div className={style.body}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
