import React from 'react';

import demo from 'assets/rider.svg';

import style from './job.module.scss';
import Loading from 'components/loading';

const JobDetail = ({ job, loader }) => {
  return (
    <>
      {loader ? (
        <div className={style.loader}>
          <Loading />
        </div>
      ) : (
        <div className={style.main}>
          <div className={style.header}>
            <div className={style.img}>
              <img src={job?.positionInfo?.image || demo} alt="" />
            </div>
            <div className={style.right}>
              <h1>General Information</h1>
              <div className={style.grid}>
                <h6>Job Title</h6>
                <p>{job?.jobTitle || '-'}</p>
                <h6>Job ID</h6>
                <p># {job?.jobId}</p>
                <h6>Employment Type</h6>
                <p>{job?.employmentType || '-'}</p>
                <h6>Start Date</h6>
                <p>{job?.startDate || '-'}</p>
                <h6>Posted Date</h6>
                <p>{job?.postedAt || '-'}</p>
                <h6>Posted By</h6>
                <p>{job?.postedBy || '-'}</p>
                <h6>Salary</h6>
                <p>{job?.salary}</p>
                <h6>Live in</h6>
                <p>{job?.positionInfo?.liveIn || '-'}</p>
                <h6>What Are Employer's Expectations</h6>
                <p>{job?.positionInfo?.employeeExpectations || '-'}</p>
              </div>
            </div>
          </div>
          <div className={style.location}>
            <h1>Location</h1>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Home Base</h6>
              </div>
              <div className={style.right}>
                <p>{job?.homeBaseAddress || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Currently Located</h6>
              </div>
              <div className={style.right}>
                <p>{job?.currentlyLocated || '-'}</p>
              </div>
            </div>
          </div>
          <div className={style.location}>
            <h1>Preferred Candidate</h1>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Gender</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.gender || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Language</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredLanguages || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Nationality</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.nationality.join(', ') || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Availability</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.availability || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Candidate Currently Based </h6>
              </div>
              <div className={style.right}>
                <p>
                  {job?.preferredCandidate?.candidateCurrentlyBased.join(
                    ', '
                  ) || '-'}
                </p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Professional Equine Experience</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.professionalExperience || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Are you Looking for a Team/Couple?</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.team || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Possibility To Bring Your own Horse?</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.bringOwnHorse || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Possibility To Have Your Own Dog?</h6>
              </div>
              <div className={style.right}>
                <p>{job?.preferredCandidate?.bringOwnDog || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Visa</h6>
              </div>
              <div className={style.right}>
                <p>
                  {job?.preferredCandidate?.visa?.visaType.join(', ') || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className={style.location}>
            <h1>Competition</h1>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Competition Name</h6>
              </div>
              <div className={style.right}>
                <p>{job?.positionInfo?.competition?.name || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Competition Country</h6>
              </div>
              <div className={style.right}>
                <p>
                  {job?.positionInfo?.competition?.country?.join(', ') || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className={style.location}>
            <h1>About Position</h1>
            <div
              className={style.header}
              style={{
                alignItems: 'flex-start',
              }}
            >
              <div className={style.img}>
                <h6>English</h6>
              </div>
              <div className={style.right}>
                <p>{job?.positionInfo?.aboutThePosition || '-'}</p>
              </div>
            </div>
            <div
              className={style.header}
              style={{
                alignItems: 'flex-start',
              }}
            >
              <div className={style.img}>
                <h6>
                  {job?.positionInfo?.aboutThePositionOtherLanguage || '-'}
                </h6>
              </div>
              <div className={style.right}>
                <p>
                  {job?.positionInfo?.aboutThePositionOtherLanguageText || '-'}
                </p>
              </div>
            </div>
          </div>
          <div className={style.location}>
            <h1>Company Information</h1>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Company Name</h6>
              </div>
              <div className={style.right}>
                <p>{job?.companyInfo?.companyName || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Company Type</h6>
              </div>
              <div className={style.right}>
                <p>{job?.companyInfo?.companyType || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Employees</h6>
              </div>
              <div className={style.right}>
                <p>{job?.companyInfo?.companySize || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Horses</h6>
              </div>
              <div className={style.right}>
                <p>{job?.companyInfo?.numOfHorses || '-'}</p>
              </div>
            </div>
            <div className={style.header}>
              <div className={style.img}>
                <h6>Level of Operations</h6>
              </div>
              <div className={style.right}>
                <p>{job?.companyInfo?.levelOfOperation || '-'}</p>
              </div>
            </div>
          </div>
        </div>
      )}{' '}
    </>
  );
};

export default JobDetail;
