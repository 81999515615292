import React from 'react';

import style from './tabs.module.scss';

const TabSection = ({ tabs, active, setActive }) => {
  return (
    <>
      <div className={style.tabs}>
        {tabs?.map((ele, index) => (
          <p
            onClick={() => setActive(index)}
            style={{
              color: active === index ? '#fff' : '',
              backgroundColor: active === index ? '#2A2E34' : '',
              boxShadow:
                active === index ? '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' : '',
            }}
            key={index}
          >
            {ele.name}
          </p>
        ))}
      </div>
    </>
  );
};

export default TabSection;
