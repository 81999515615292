import React from 'react';
import Select from 'react-select';

import style from './selection.module.scss';

const Selection = ({
  id,
  name,
  label,
  value,
  options,
  setValue,
  iconClass,
  menuWidth,
  placeholder,
  handleChange,
}) => {
  const selectOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  const onChange = (name, e) => {
    setValue(name, e);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#c7c7c9' : 'white',
      width: menuWidth || '150px',
      '&:hover': {
        color: 'white',
        backgroundColor: '#c7c7c9',
      },
    }),

    menu: (provided) => ({
      ...provided,
      width: menuWidth || '150px',
      zIndex: 3,
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      borderRadius: '0px',
      padding: '0px',
      backgroundColor: 'transparent',
      height: '100%',
      minHeight: '100%',
      borderWidth: '0px',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:active': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
      height: '100%',
      border: 'none !important',
      borderWidth: '0px !important',
      '&:hover': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:active': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
    }),
    container: (provided) => ({
      ...provided,
      border: 'none',
      borderWidth: '0px',
      '&:hover': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:active': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#8b909a',
      fontSize: '14px',
      fontWeight: 400,
    }),
    input: (provided) => ({
      ...provided,
      color: '#2a2e34',
      fontSize: '16px',
      fontWeight: 400,
      border: 'none',
      borderWidth: '0px',
      '&:hover': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:active': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
    }),

    singleValue: (provided) => ({
      ...provided,
      color: '#2a2e34',
      fontSize: '16px',
      fontWeight: 400,
      border: 'none',
      borderWidth: '0px',
      '&:hover': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
      '&:active': {
        border: '1px solid transparent',
        borderWidth: '0px',
        boxShadow: 'none',
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
  };

  return (
    <>
      {label && <label className={style.label}>{label}</label>}

      <div className={style.container}>
        <div
          style={{
            flex: 1,
          }}
        >
          <Select
            name={name}
            value={value}
            styles={customStyles}
            options={selectOptions}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(name, e);
              handleChange && handleChange();
            }}
          />
        </div>
        <label
          htmlFor={id}
          style={{
            width: '20px',
          }}
        >
          <div className={`${style.icon} ${iconClass}`}>
            <div className={style.img}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="7"
                viewBox="0 0 13 7"
                fill="none"
                style={{
                  transform: 'rotate(180deg)',
                }}
              >
                <path
                  d="M11.3027 6L6.22368 1L1.14463 6"
                  stroke="#9E9FA5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default Selection;
