import React from 'react';

import style from './tag.module.scss';

const Tags = ({ text, bgColor, textColor }) => {
  return (
    <>
      <div
        className={style.tag}
        style={{
          backgroundColor: bgColor,
        }}
      >
        <p
          style={{
            color: textColor,
          }}
        >
          {text}
        </p>
      </div>
    </>
  );
};

export default Tags;
