import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import Jobs from 'pages/jobs';
import Login from 'pages/login';
import Layout from 'components/layout';
import { Protected } from './protected';
import Candidates from 'pages/candidates';
import JobsReported from 'pages/job-reported';
import Subscriptions from 'pages/subscriptions';
import SingleJob from 'pages/jobs/job-listing/single-job';
import SingleCandidate from 'pages/candidates/candidate-listing/single-candidate';

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" element={<Login />} />
      <Route path="/jobs/*" element={<Protected element={<Jobs />} />} />
      <Route
        path="/candidates/*"
        element={<Protected element={<Candidates />} />}
      />
      <Route
        path="/job-reported"
        element={<Protected element={<JobsReported />} />}
      />
      <Route
        path="/subscriptions/*"
        element={<Protected element={<Subscriptions />} />}
      />
      <Route
        path="/candidates/details/:id/*"
        element={<Protected element={<SingleCandidate />} />}
      />
      <Route
        path="/jobs/details/:id/*"
        element={<Protected element={<SingleJob />} />}
      />
      <Route path="*" element={<Protected element={<Layout />} />} />
    </Switch>
  );
};

export default Routes;
