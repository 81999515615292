import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import Input from 'components/input';
import Table from 'components/table';
import Selection from 'components/select';
import DelModal from 'components/delete-modal';

import Filters from './filters';
import { columns, rows, sortOptions } from './helper';
import { deleteJob, getAllJobs } from 'api-services/jobs';

import menu from 'assets/menu.svg';
import icon from 'assets/filter.svg';

import style from './listing.module.scss';

const JobListing = () => {
  const containerRef = useRef();
  const [jobs, setJobs] = useState([]);
  const [open, setOpen] = useState(false);
  const [sortIn, setSortIn] = useState(-1);
  const [popUp, setPopUp] = useState(false);
  const [loader, setLoader] = useState(true);
  const [jobsCount, setJobsCount] = useState(0);
  const [filterCount, setFilterCount] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 20,
  });

  const {
    watch,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const getJobs = async (data) => {
    const params = {
      sortIn,
      page: pagination?.page,
      pageSize: pagination?.pageSize,
      sortBy: watch('sortBy')?.value || 'Posted Date',
      ...(data?.postedAt && { createdAt: data?.postedAt }),
      ...(data?.startDate && { startDate: data?.startDate }),
      ...(data?.jobStatus && { status: data?.jobStatus?.value }),
      ...(data?.jobTitle && { jobTitle: data?.jobTitle?.value }),
      ...(data?.minApplicants && { minApplicants: data?.minApplicants }),
      ...(data?.maxApplicants && { maxApplicants: data?.maxApplicants }),
      ...(data?.employmentType && {
        employmentType: data?.employmentType?.value,
      }),
    };
    await getAllJobs({
      jobs,
      params,
      setJobs,
      setLoader,
      pageLoader,
      setJobsCount,
    });
    setBtnLoader(false);
    setOpenFilter(false);
    setPageLoader(false);
  };

  const handleDelete = async () => {
    setOpen(false);
    setPopUp(false);
    const res = await deleteJob({ id: selectedJobId });
    res && getJobs();
  };

  useEffect(() => {
    setValue('sortBy', { value: 'Posted Date', label: 'Posted Date' });
  }, []);

  useEffect(() => {
    getJobs();
  }, [sortIn, pagination]);

  const handleChange = async ({ target }) => {
    setLoader(true);
    const params = {
      sortIn,
      page: 0,
      pageSize: 20,
      search: target.value,
      sortBy: watch('sortBy')?.value || 'Posted Date',
    };
    await getAllJobs({
      jobs,
      params,
      setJobs,
      setLoader,
      pageLoader,
      setJobsCount,
    });
    setBtnLoader(false);
    setOpenFilter(false);
    setPageLoader(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
        if (jobs?.length < jobsCount && !loader) {
          setPageLoader(true);
          setPagination((prev) => ({
            ...prev,
            page: prev?.page + 1,
          }));
        }
      }
    };

    containerRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      containerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, jobs]);

  return (
    <>
      <div className={style.main}>
        <div className={style.flex}>
          <div className={style.left}>
            <div className={style.filter} onClick={() => setOpenFilter(true)}>
              <img src={icon} alt="" />
              <p>{filterCount && `(${filterCount})`}</p>
              <p> Filters</p>
            </div>
            <Input
              name="search"
              search={true}
              register={register}
              handleChange={handleChange}
              placeholder="Type and Press Enter"
            />
          </div>
          <div className={style.right}>
            <p className={style.sort}>Sort by:</p>
            <div
              style={{
                width: '150px',
              }}
            >
              <Selection
                id="Select"
                name="sortBy"
                setValue={setValue}
                placeholder="Select"
                options={sortOptions}
                value={watch('sortBy')}
                handleChange={() => {
                  setLoader(true);
                  getJobs();
                }}
              />
            </div>
            <div className={style.ascending}>
              <div
                className={`${style.svg} ${style.tooltip}`}
                onClick={() => {
                  setSortIn(1);
                  setLoader(true);
                }}
                style={{
                  borderRadius: '5px 5px 0px 0px',
                  borderBottom: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={sortIn === 1 ? '#2A2E34' : '#d6d6d6'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Ascending</span>
              </div>
              <div style={{ borderBottom: '1px solid #D6D6D6' }} />
              <div
                className={`${style.svg} ${style.tooltip}`}
                onClick={() => {
                  setSortIn(-1);
                  setLoader(true);
                }}
                style={{
                  borderRadius: '0px 0px 5px 5px',
                  borderTop: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  style={{
                    transform: 'rotate(180deg)',
                  }}
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={sortIn === 1 ? '#d6d6d6' : '#2A2E34'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Descending</span>
              </div>
            </div>
            <img src={menu} alt="" className={style.img} />
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Table
            rows={rows({
              jobs,
              popUp,
              setOpen,
              setPopUp,
              selectedJobId,
              setSelectedJobId,
            })}
            loader={loader}
            columns={columns}
            minWidth={'2020px'}
            pageLoader={pageLoader}
            containerRef={containerRef}
            classBody={style.bodyClass}
            tableHeight={style.bodyClass}
          />
        </div>
      </div>
      {openFilter && (
        <Filters
          {...{
            reset,
            watch,
            getJobs,
            register,
            setValue,
            btnLoader,
            setLoader,
            openFilter,
            isSubmitting,
            setBtnLoader,
            handleSubmit,
            setOpenFilter,
            setFilterCount,
          }}
        />
      )}
      <DelModal {...{ handleDelete, open, setOpen }} />
    </>
  );
};

export default JobListing;
