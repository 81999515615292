import React, { useEffect } from 'react';

import Input from 'components/input';
import Button from 'components/button';
import Selection from 'components/select';
import { availability, country_list, getFilterCount, statuses } from './helper';

import cross from 'assets/Close.svg';

import style from './filter.module.scss';

const Filters = ({
  watch,
  reset,
  setValue,
  register,
  btnLoader,
  openFilter,
  setBtnLoader,
  isSubmitting,
  handleSubmit,
  getApplicants,
  setOpenFilter,
  setFilterCount,
}) => {
  
  useEffect(() => {
    const count = getFilterCount(watch);
    setValue('filterCount', count);
  }, [watch()]);

  return (
    <>
      <div className={style.filter}>
        <div className={style.filterHeader}>
          <h1>Filters</h1>
          <img src={cross} alt="" onClick={() => setOpenFilter(false)} />
        </div>
        <form onSubmit={handleSubmit(getApplicants)}>
          <div className={style.body}>
            <div className={style.inner}>
              <div>
                <Selection
                  id="Select"
                  name="status"
                  options={statuses}
                  setValue={setValue}
                  placeholder="Select"
                  value={watch('status')}
                  label="Applicant Status"
                />
              </div>
              <div>
                <Selection
                  id="Select"
                  name="availability"
                  setValue={setValue}
                  placeholder="Select"
                  label={'Availability'}
                  options={availability}
                  value={watch('availability')}
                />
              </div>
              <div>
                <Selection
                  id="Select"
                  name="location"
                  label={'Location'}
                  setValue={setValue}
                  placeholder="Select"
                  options={country_list}
                  value={watch('location')}
                />
              </div>
              <div>
                <Input
                  type={'date'}
                  name="dateApplied"
                  register={register}
                  label={'Applied Date'}
                />
              </div>
            </div>
            <div className={style.btnDiv}>
              <Button
                text={'Reset'}
                type={'button'}
                btnClass={style.btn}
                isLoading={btnLoader}
                loaderClass={style.loaderClass}
                handleClick={() => {
                  setFilterCount();
                  setBtnLoader(true);
                  reset();
                  setValue('status', null);
                  setValue('location', null);
                  setValue('availability', null);
                  getApplicants();
                }}
              />
              <Button
                text={'Apply'}
                type={'submit'}
                isLoading={isSubmitting}
                handleClick={() => setFilterCount(watch('filterCount'))}
              />
            </div>
          </div>
        </form>
      </div>
      {openFilter && (
        <div className={style.backdrop} onClick={() => setOpenFilter(false)} />
      )}
    </>
  );
};

export default Filters;
