import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Table from 'components/table';
import Input from 'components/input';
import Selection from 'components/select';
import RemoveModal from 'components/remove-modal';

import Filters from './filters';
import { getJobApplicants, removeApplication } from 'api-services/jobs';
import { cardData, columns, onSearch, rows, sortOptions } from './helper';

import menu from 'assets/menu.svg';
import icon from 'assets/filter.svg';

import style from './applicant.module.scss';

const Applicants = () => {
  const { id } = useParams();
  const containerRef = useRef();

  const [page, setPage] = useState(0);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [sortIn, setSortIn] = useState(-1);
  const [popUp, setPopUp] = useState(false);
  const [loader, setLoader] = useState(true);
  const [filterCount, setFilterCount] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [applicationId, setApplicationId] = useState();

  const {
    watch,
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const getApplicants = async (data) => {
    setLoader(true);
    const params = {
      page,
      sortIn,
      pageSize: 15,
      sortBy: watch('sortBy')?.value || 'AppliedDate',
      ...(data?.status && { status: data?.status?.value }),
      ...(data?.location && { location: data?.location?.value }),
      ...(data?.dateApplied && { dateApplied: data?.dateApplied }),
      ...(data?.availability && { availability: data?.availability?.value }),
    };
    await getJobApplicants({
      id,
      data,
      params,
      setData,
      setLoader,
      pageLoader,
    });
    setBtnLoader(false);
    setOpenFilter(false);
    setPageLoader(false);
  };

  const handleDelete = async () => {
    setOpen(false);
    setPopUp(false);
    const res = await removeApplication({ id: applicationId });
    res && getApplicants();
  };

  useEffect(() => {
    setValue('sortBy', { value: 'Applied Date', label: 'Applied Date' });
  }, []);

  useEffect(() => {
    getApplicants();
  }, [id, sortIn, page]);

  const handleChange = (e) => {
    const { value } = e.target;
    value ? onSearch({ search: value, data, setData }) : getApplicants();
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
        if (data?.candidates?.length < data?.count && !loader) {
          setPageLoader(true);
          setPage(page + 1);
        }
      }
    };

    containerRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      containerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, data?.candidates]);

  return (
    <>
      <div className={style.main}>
        <div className={style.grid}>
          {cardData(data)?.map((ele, index) => (
            <div className={style.card} key={index}>
              <h1>{ele.heading}</h1>
              <p>{ele.title}</p>
            </div>
          ))}
        </div>

        <div className={style.flex}>
          <div className={style.left}>
            <div className={style.filter} onClick={() => setOpenFilter(true)}>
              <img src={icon} alt="" />
              <p>{filterCount && `(${filterCount})`}</p>
              <p> Filters</p>
            </div>
            <Input
              name="search"
              search={true}
              register={register}
              handleChange={handleChange}
              placeholder="Type and Press Enter"
            />
          </div>
          <div className={style.right}>
            <p className={style.sort}>Sort by:</p>
            <div
              style={{
                width: '150px',
              }}
            >
              <Selection
                id="Select"
                name="sortBy"
                setValue={setValue}
                placeholder="Select"
                options={sortOptions}
                value={watch('sortBy')}
                handleChange={() => getApplicants()}
              />
            </div>
            <div className={style.ascending}>
            <div
                className={`${style.svg} ${style.tooltip}`}
                onClick={() => setSortIn(1)}
                style={{
                  borderRadius: '5px 5px 0px 0px',
                  borderBottom: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={sortIn === 1 ? '#2A2E34' : '#d6d6d6'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Ascending</span>
              </div>
              <div style={{ borderBottom: '1px solid #D6D6D6' }} />
              <div
                className={`${style.svg} ${style.tooltip}`}
                onClick={() => setSortIn(-1)}
                style={{
                  borderRadius: '0px 0px 5px 5px',
                  borderTop: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  style={{
                    transform: 'rotate(180deg)',
                  }}
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={sortIn === 1 ? '#d6d6d6' : '#2A2E34'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Descending</span>
              </div>
            </div>
            <img src={menu} alt="" className={style.img} />
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
          }}
        >
          <Table
            rows={rows({
              data,
              popUp,
              setOpen,
              setPopUp,
              applicationId,
              setApplicationId,
            })}
            loader={loader}
            columns={columns}
            minWidth={'1300px'}
            pageLoader={pageLoader}
            containerRef={containerRef}
            classBody={style.bodyClass}
            tableHeight={style.bodyClass}
          />
        </div>
      </div>
      {openFilter && (
        <Filters
          {...{
            reset,
            watch,
            register,
            setValue,
            btnLoader,
            openFilter,
            setBtnLoader,
            isSubmitting,
            handleSubmit,
            getApplicants,
            setOpenFilter,
            setFilterCount,
          }}
        />
      )}
      <RemoveModal {...{ open, setOpen, handleDelete, delItem: 'Applicant' }} />
    </>
  );
};

export default Applicants;
