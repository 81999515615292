import React from 'react';

import style from './button.module.scss';
import Loading from 'components/loading';

const Button = ({
  text,
  iconStart,
  iconEnd,
  handleClick,
  type,
  className,
  isLoading,
  btnClass,
  disabled,
  form,
  imgClass,
  loaderClass,
  ...restOfProps
}) => {
  return (
    <>
      <button
        className={`${style.btn} ${btnClass && btnClass}`}
        type={type}
        form={form}
        onClick={handleClick}
        disabled={isLoading || disabled ? true : false}
        style={{
          pointerEvents: isLoading || disabled ? 'none' : 'auto',
          position: 'relative',
        }}
        {...restOfProps}
      >
        {isLoading ? (
          <Loading loaderClass={`${style.loaderClass} ${loaderClass}`} />
        ) : (
          text && (
            <span className={`${style.btnTitle} ${className}`}>{text}</span>
          )
        )}
      </button>
    </>
  );
};

export default Button;
