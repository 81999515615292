import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import Input from 'components/input';
import Table from 'components/table';
import { columns, rows } from './helper';

import { getAllReports } from 'api-services/jobReports';

import menu from 'assets/menu.svg';

import style from './listing.module.scss';

const ReportListing = () => {
  const containerRef = useRef();
  const { register } = useForm();

  const [search, setSearch] = useState('');
  const [count, setCount] = useState(true);
  const [loader, setLoader] = useState(true);
  const [reports, setReports] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 20,
  });

  const getCandidates = useCallback(async () => {
    const params = {
      page: pagination?.page,
      ...(search && { search }),
      pageSize: pagination?.pageSize,
    };

    await getAllReports({
      params,
      setCount,
      setLoader,
      setReports,
      setPageLoader,
    });
  }, [pagination, search]);

  const handleChange = _.debounce(({ target }) => {
    setLoader(true);
    setPagination({ page: 0, pageSize: 20 });
    setSearch(target?.value);
  }, 1000);

  useEffect(() => {
    getCandidates({});
  }, [getCandidates]);

  useEffect(() => {
    const containerCurrent = containerRef?.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerCurrent;

      if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
        if (reports?.length < count && !loader) {
          setPageLoader(true);
          setPagination((prev) => ({ ...prev, page: prev?.page + 1 }));
        }
      }
    };

    containerCurrent?.addEventListener('scroll', handleScroll);
    return () => {
      containerCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, count, loader, reports]);

  return (
    <>
      <div className={style.main}>
        <div className={style.flex}>
          <div className={style.left}>
            <Input
              name="search"
              search={true}
              register={register}
              handleChange={handleChange}
              placeholder="Type and Press Enter"
            />
          </div>
          <div className={style.right}>
            <img src={menu} alt="" className={style.img} />
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Table
            rows={rows({ reports })}
            {...{
              loader,
              columns,
              pageLoader,
              containerRef,
            }}
            minWidth={'2000px'}
            classBody={style.bodyClass}
            tableHeight={style.bodyClass}
          />
        </div>
      </div>
    </>
  );
};

export default ReportListing;
