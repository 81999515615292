import Tags from 'components/tags';

import link from 'assets/link.svg';
import demo from 'assets/rider.svg';
import video from 'assets/video.svg';
import remove from 'assets/remove-doc.svg';

import style from './applications.module.scss';

export const onSearch = ({ search, data, setData }) => {
  const filteredData = data?.appliedJobs?.filter((appliedJob) => {
    return Object.values(appliedJob).some((fieldValue) => {
      if (Array.isArray(fieldValue)) {
        return fieldValue.some((value) =>
          value.toLowerCase().includes(search.toLowerCase())
        );
      }
      return String(fieldValue).toLowerCase().includes(search.toLowerCase());
    });
  });
  setData((prev) => ({ ...prev, appliedJobs: filteredData }));
};

export const columns = [
  { key: 'jobTitle', name: 'Job Title', width: '250px' },
  { key: 'employmentType', name: 'Employment Type', width: '150px' },
  { key: 'jobStatus', name: 'Job Status', width: '150px' },
  { key: 'applicationStatus', name: 'Application Status', width: '200px' },
  { key: 'videoInterview', name: 'Video Interview', width: '90px' },
  { key: 'companyName', name: 'Company Name', width: '170px' },
  { key: 'postedDate', name: 'Job Posted Date', width: '120px' },
  { key: 'appliedDate', name: 'Applied Date', width: '120px' },
  { key: 'actions', name: 'Actions', width: '80px' },
];

export const sortOptions = [
  'Job Title',
  'Employment Type',
  'Job Status',
  'Application Status',
  'Video Interview',
  'Company Name',
  'Posted Date',
  'Applied Date',
];

export const rows = ({ data, setOpen, setPopUp, setSelectedId }) => {
  return (
    data?.appliedJobs?.length > 0 &&
    data?.appliedJobs?.map((row) => {
      const jobStatusColor =
        row?.jobStatus === 'Active' ? '#8FD880' : '#FF5A5A';
      const applcationStatusColor =
        row?.applicationStatus === 'Shortlisted' ? '#8FD880' : '#FF5A5A';

      return {
        jobStatus: row?.jobStatus,
        postedDate: row?.postedDate,
        companyName: row?.companyName,
        appliedDate: row?.appliedDate,
        employmentType: row?.employmentType,
        jobTitle: {
          render: () => {
            return (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.yehaww.com/jobs/details/${row?.jobId}`}
                >
                  <div className={style.title}>
                    <img src={row?.jobPic || demo} alt="" />
                    <p>{row?.jobTitle}</p>
                    <img src={link} alt="" />
                  </div>
                </a>
              </>
            );
          },
        },
        jobStatus: {
          render: () => {
            return (
              <>
                <Tags text={row?.jobStatus} bgColor={jobStatusColor} />
              </>
            );
          },
        },
        applicationStatus: {
          render: () => {
            return (
              <>
                <Tags
                  text={row?.applicationStatus}
                  bgColor={applcationStatusColor}
                />
              </>
            );
          },
        },
        videoInterview: {
          render: () => {
            return (
              <>
                <div className={style.title}>
                  <p>{row?.videoInterview}</p>
                  {row?.interviewVideoUrl && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={row?.interviewVideoUrl}
                    >
                      <img src={video} alt="" />
                    </a>
                  )}
                </div>
              </>
            );
          },
        },

        actions: {
          render: () => {
            return (
              <div className={`${style.svg} ${style.tooltip}`}>
                <img
                  alt=""
                  src={remove}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpen(true);
                    setPopUp(false);
                    setSelectedId(row?._id);
                  }}
                />
                <span className={style.tooltiptext}>Remove</span>
              </div>
            );
          },
        },
      };
    })
  );
};
