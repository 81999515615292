import React from 'react';

import Layout from 'components/layout';
import ReportListing from './listing';

import style from './reports.module.scss';

const JobsReported = () => {
  return (
    <>
      <Layout>
        <div className={style.mainClass}>
          <h1 className={style.h1}>Jobs Reported</h1>
          <div className={style.tabClass}>
            <ReportListing />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default JobsReported;
