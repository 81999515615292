import React, { useEffect } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import logo from 'assets/logo.svg';
import Input from 'components/input';
import Button from 'components/button';
import { useSelector } from 'react-redux';
import { login } from 'api-services/auth';

import style from './login.module.scss';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.app.token);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    data.email = data.email ? data.email.toLowerCase() : '';
    await login({ data, navigate, dispatch, setError });
  };

  return (
    <div className={style.main}>
      {!token && (
        <div className={style.innerDiv}>
          <img src={logo} alt="" className={style.img} />
          <div className={style.card}>
            <h1>Let’s Start</h1>
            <p className={style.p}>
              Enter your credentials to log into your account
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={style.input}
                style={{
                  marginBottom: '15px',
                }}
              >
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  register={register}
                  placeholder={'email@example.com'}
                  errorMessage={errors?.email?.message}
                />
              </div>
              <div className={style.input}>
                <Input
                  name="password"
                  type="password"
                  label="Password"
                  register={register}
                  placeholder={'************'}
                  errorMessage={errors?.password?.message}
                />
              </div>
              <p className={style.span}>Forget Password?</p>
              <Button isLoading={isSubmitting} type="submit" text="Login" />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;

const schema = yup.object().shape({
  email: yup.string().required('Required').email('Please enter a valid Email'),
  password: yup.string().required('Required'),
});
