import React, { useState } from 'react';

import eye from 'assets/show-pass.svg';
import icon from 'assets/search.svg';
import eyeHide from 'assets/hide-pass.svg';

import style from './input.module.scss';

const Input = ({
  name,
  type,
  label,
  search,
  infoText,
  register,
  container,
  errorClass,
  inputField,
  placeholder,
  handleChange,
  errorMessage,
  inputWrapperClass,
}) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className={`${style.inputContainer} ${container && container}`}>
      {label && <label>{label}</label>}
      <div
        className={`${style.inputWrapper} ${
          inputWrapperClass && inputWrapperClass
        }`}
        style={{
          position: 'relative',
        }}
      >
        {search && (
          <img
            src={icon}
            className={style.date1}
            alt="search"
            style={{
              cursor: 'pointer',
            }}
          />
        )}
        <input
          placeholder={placeholder}
          {...(register && register(name))}
          className={`${style.input} ${inputField}`}
          onChange={handleChange && handleChange}
          type={showPass ? 'text' : type ? type : 'text'}
          style={{
            marginTop: label && '4px',
            paddingLeft: search && '30px',
            paddingRight: type === 'password' && ' 30px',
          }}
        />
        {type === 'password' && (
          <img
            alt="Eye"
            className={style.date}
            src={showPass ? eye : eyeHide}
            onClick={() => setShowPass(!showPass)}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </div>
      {errorMessage ? (
        <span className={`${style.errorMessage} ${errorClass}`}>
          {errorMessage}
        </span>
      ) : (
        <span className={style.message}>{infoText}</span>
      )}
    </div>
  );
};

export default Input;
