import { apiRequest } from '../utils/helper';

export const getRequests = async () => {
  const res = await apiRequest({
    type: 'get',
    path: '/subscription/cancel-requests',
  });
  if (res?.status === 200) {
    return res?.data;
  }
};

export const cancelRequest = async ({ id }) => {
  const res = await apiRequest({
    type: 'put',
    path: `/subscription/cancel-request/${id}`,
  });
  if (res?.status === 200) {
    return res?.data;
  }
};
