import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Tabs from 'components/tabs';
import Layout from 'components/layout';

import JobDetail from './job-detail';
import Applicants from './applicants';
import { getJob } from 'api-services/jobs';

import style from './job.module.scss';

const SingleJob = () => {
  const { id } = useParams();

  const [job, setJob] = useState({});
  const [active, setActive] = useState(0);
  const [loader, setLoader] = useState(true);

  const getDetails = async () => {
    await getJob({ id, setJob });
    setLoader(false);
  };

  useEffect(() => {
    getDetails();

    if (window.location.href.endsWith('/applicants')) {
      setActive(1);
    } else {
      setActive(0);
    }
  }, [id]);

  useEffect(() => {
    const handlePopState = () => {
      const currentURL = window.location.href;
      if (currentURL.endsWith('/applicants')) {
        setActive(1);
      } else {
        setActive(0);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleTabChange = (index) => {
    setActive(index);
    if (index === 1) {
      window.history.pushState(null, '', `/jobs/details/${id}/applicants`);
    } else {
      window.history.pushState(null, '', `/jobs/details/${id}`);
    }
  };

  return (
    <>
      <Layout>
        <div className={style.mainClass}>
          <h1 className={style.h1}>
            {'Jobs >'} {job?.jobTitle} {job?.jobId && `(${job?.jobId})`}
          </h1>
          <div className={style.tabClass}>
            <Tabs
              tabs={tabs(job, loader)}
              active={active}
              setActive={handleTabChange}
              children={tabs(job, loader)[active].children}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SingleJob;

const tabs = (job, loader) => [
  {
    name: 'Job Detail',
    children: <JobDetail job={job} loader={loader} />,
  },
  { name: 'Applicants', children: <Applicants /> },
];
