import { apiRequest } from '../utils/helper';

import { setFormError } from 'helpers/hook-form-helper';

export const getAllCandidates = async ({
  params,
  setError,
  setCount,
  setLoader,
  candidates,
  pageLoader,
  setCandidates,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: '/candidate/getAll',
    params,
  });
  setFormError(res, setError);
  if (res?.status === 200) {
    setCount(res?.data?.count);
    pageLoader
      ? setCandidates([...candidates, ...res?.data?.candidates])
      : setCandidates(res?.data?.candidates);
    setLoader(false);
  }
};

export const editCandidateSetting = async ({ body }) => {
  await apiRequest({
    type: 'put',
    path: '/candidate/settings',
    body,
  });
};

export const getAppliedJobs = async ({
  id,
  params,
  setData,
  setLoader,
  pageLoader,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: `candidate/appliedJobs/${id}`,
    params,
  });
  if (res?.status === 200) {
    pageLoader
      ? setData((prev) => ({
          ...prev,
          appliedJobs: [...prev?.appliedJobs, ...res?.data?.appliedJobs],
        }))
      : setData(res?.data);
    setLoader(false);
  }
};

export const removeApplication = async ({ id }) => {
  const res = await apiRequest({
    type: 'delete',
    path: `candidate/removeApplication/${id}`,
  });
  if (res?.status === 200) {
    return res?.data;
  }
};
