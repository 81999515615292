export const statuses = ['Active', 'Position Filled', 'Expired'];

export const types = [
  'Seasonal',
  'Temporary',
  'Freelance',
  'Volunteer',
  'Permanent Position',
];

export const getFilterCount = (watch) => {
  let count = 0;
  if (watch('jobTitle')) count++;
  if (watch('postedAt')) count++;
  if (watch('jobStatus')) count++;
  if (watch('startDate')) count++;
  if (watch('employmentType')) count++;
  if (watch('minApplicants') || watch('maxApplicants')) count++;

  return count;
};

export const titles = [
  'Flatrider/Groom',
  'Groom/Au Pair',
  'Groom/Housekeeper',
  'Property Manager',
  'Barn Manager',
  'Polo Groom',
  'Racing Groom',
  'Jockey',
  'Stall Mucker',
  'Braider',
  'Freelance Groom',
  'Driver',
  'Freelance Driver',
  'Handyman',
  'Working Student',
  'Gardener',
  'Security',
  'Farrier',
  'Flight Groom',
  'Veterinary',
  'Dentist',
  'Chiropractor',
  'Bodyworker',
  'Nutritionist',
  'Equine Real Estate Agent',
  'Western rider',
  'Equine Therapist',
  'Equine Dental Technician',
  'Equine Nutritionist',

  'Equine Veterinarian',
  'Equine Veterinarian Technician',
  'Equine Bodyworker',
  'Broodmare Manager',
  'Riding Instructor',
  'Competition Judge',
  'Show Jumping Course Designer',
  'Equine Insurance Agent',
  'Equine Product Sales Representative',
  'Event/Competition Promoter',
  'Equine Photographer/Video',
  'Ring crew/ Arena maintenance',
  'Cleaning crew',
  'Stable crew',
  'Catering',
  'Show office personnel',
  'Announcer',
  'Guest services',
  'VIP hostess',
  'Show manager',
  'Marketing',
  'Retail personnel',
  'Media',
  'Parking crew',
  'Property maintenance',
  'Personal Assistant',
  'Public Relations/Social Media',
  'Accountant',
  'Sales/Marketing',
  'Event Coordinator',
  'Secretary/Admin',
  'Show Rider',
  'Show Groom',
  'Home Groom',
  'Exercise Rider',
  'Young Horse Rider',
  'Home Groom/Flat Rider',
  'Home Groom/Manager',
  'Manager/Flat Rider',
  'Showjumping Trainer',
  'Dressage Trainer',
  'Eventing Trainer',
  'Groom',
  'Hunter Trainer',
  'Hunter Rider',
  'Hunter Groom',
  'Equitation Trainer',
  'Equitation Rider',
  'Equitation Groom',
];
