import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from './app-splice';

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
});

export const {
  setUser,
  setLogout,
  setAppLoader,
  setDashboardData,
  setCandidateSearch,
} = appSlice.actions;

export const RootState = store.getState;
export const AppDispatch = store.dispatch;
