import { Link } from 'react-router-dom';

import Tags from 'components/tags';
import menu from 'assets/menu.svg';
import demo from 'assets/rider.svg';
import style from './listing.module.scss';

export const columns = [
  { key: 'jobTitle', name: 'Job Title', width: '250px' },
  { key: 'employment', name: 'Employment Type', width: '150px' },
  { key: 'status', name: 'Status', width: '150px' },
  { key: 'companyName', name: 'Company Name', width: '200px' },
  { key: 'applicants', name: 'Applicants', width: '90px' },
  { key: 'salary', name: 'Salary', width: '180px' },
  { key: 'startDate', name: 'Starting Date', width: '120px' },
  { key: 'postName', name: 'Posted By (Name)', width: '150px' },
  { key: 'postEmail', name: 'Posted By (Email)', width: '280px' },
  { key: 'postDate', name: 'Posted Date', width: '120px' },
  { key: 'actions', name: 'Actions', width: '80px' },
];

export const sortOptions = [
  'Job Title',
  'Employment Type',
  'Status',
  'Company Name',
  'Applicants',
  'Salary',
  'Starting Date',
  'Posted By (Name)',
  'Posted By (Email)',
  'Posted Date',
];

export const rows = ({
  jobs,
  popUp,
  setOpen,
  setPopUp,
  selectedJobId,
  setSelectedJobId,
}) => {
  return (
    jobs?.length > 0 &&
    jobs.map((row) => {
      const bgColor =
        row?.status === 'Expired'
          ? '#8B909A'
          : row?.status === 'Position Filled'
          ? '#FF5A5A'
          : '#57B894';
      const salary = `${row?.salary || '-'} ${row?.currency || ''} ${
        row?.salaryBasis || ''
      }`;

      return {
        jobTitle: {
          render: () => {
            return (
              <>
                <Link to={`/jobs/details/${row?.jobId}`}>
                  <div className={style.title}>
                    <img src={row?.img || demo} alt="" />
                    <p>{row?.jobTitle || '-'}</p>
                  </div>
                </Link>
              </>
            );
          },
        },
        status: {
          render: () => {
            return (
              <>
                <Tags
                  text={row?.status}
                  textColor={'#fff'}
                  bgColor={bgColor || '-'}
                />
              </>
            );
          },
        },
        salary,
        postDate: row?.createdAt || '-',
        startDate: row?.startDate || '-',
        applicants: row?.applicants || '-',
        postName: row?.employerName || '-',
        companyName: row?.companyName || '-',
        postEmail: row?.employerEmail || '-',
        employment: row?.employmentType || '-',
        actions: {
          render: () => {
            return (
              <div className={style.position}>
                <img
                  alt=""
                  src={menu}
                  onClick={() => {
                    setPopUp(true);
                    setSelectedJobId(row?.jobId);
                  }}
                />
                {popUp && row?.jobId === selectedJobId && (
                  <div className={style.menu}>
                    <p>Edit</p>
                    <p
                      onClick={() => {
                        setOpen(true);
                        setPopUp(false);
                      }}
                    >
                      Delete
                    </p>
                  </div>
                )}
                {popUp && row?.jobId === selectedJobId && (
                  <div
                    className={style.backdrop}
                    onClick={() => setSelectedJobId(null)}
                  />
                )}
              </div>
            );
          },
        },
      };
    })
  );
};
