import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Tabs from 'components/tabs';
import Layout from 'components/layout';
import Applications from './applications';

import style from './candidate.module.scss';

const SingleCandidate = () => {
  const { id } = useParams();

  const [active, setActive] = useState(0);
  const [loader, setLoader] = useState(true);
  const [candidate, setCandidate] = useState({});

  useEffect(() => {
    if (window.location.href.endsWith('/applications')) {
      setActive(1);
    } else {
      setActive(0);
    }
  }, [id]);

  useEffect(() => {
    const handlePopState = () => {
      const currentURL = window.location.href;
      if (currentURL.endsWith('/applications')) {
        setActive(1);
      } else {
        setActive(0);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleTabChange = (index) => {
    setActive(index);
    if (index === 1) {
      window.history.pushState(
        null,
        '',
        `/candidates/details/${id}/applications`
      );
    } else {
      window.history.pushState(null, '', `/candidates/details/${id}`);
    }
  };

  return (
    <>
      <Layout>
        <div className={style.mainClass}>
          <h1 className={style.h1}>
            {'Candidate >'} {candidate?.candidateName}
          </h1>
          <div className={style.tabClass}>
            <Tabs
              tabs={tabs}
              active={active}
              setActive={handleTabChange}
              children={tabs[active].children}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SingleCandidate;

const tabs = [
  {
    name: 'Candidate Details',
    children: <div>Candidate Details</div>,
  },
  { name: 'Job Applications', children: <Applications /> },
];
