import React, { useEffect } from 'react';

import Input from 'components/input';
import Button from 'components/button';
import Selection from 'components/select';
import {
  country_list,
  genderOptions,
  getFilterCount,
  availabilityOptions,
} from './helper';

import cross from 'assets/Close.svg';

import style from './filter.module.scss';

const Filters = ({
  watch,
  reset,
  register,
  setValue,
  setLoader,
  btnLoader,
  openFilter,
  setBtnLoader,
  isSubmitting,
  handleSubmit,
  getCandidates,
  setOpenFilter,
  setFilterCount,
}) => {
  useEffect(() => {
    const count = getFilterCount(watch);
    setValue('filterCount', count);
  }, [watch()]);

  return (
    <>
      <div className={style.filter}>
        <div className={style.filterHeader}>
          <h1>Filters</h1>
          <img src={cross} alt="" onClick={() => setOpenFilter(false)} />
        </div>
        <form onSubmit={handleSubmit(getCandidates)}>
          <div className={style.body}>
            <div className={style.inner}>
              <div>
                <Selection
                  id="Select"
                  name="location"
                  label="Location"
                  setValue={setValue}
                  placeholder="Select"
                  options={country_list}
                  value={watch('location')}
                />
              </div>
              <div className={style.label}>
                <label>Profile Complition Rate</label>
                <div className={style.flex}>
                  <Input
                    name="minRate"
                    type={'number'}
                    placeholder={'0'}
                    register={register}
                  />
                  <div className={style.border} />
                  <Input
                    name="maxRate"
                    type={'number'}
                    handleChange={({ target }) =>
                      setValue('maxRate', target.value)
                    }
                    placeholder={'100'}
                    register={register}
                  />
                </div>
              </div>
              <div className={style.label}>
                <label>Jobs Applied</label>
                <div className={style.flex}>
                  <Input
                    type={'number'}
                    name="minApplied"
                    placeholder={'0'}
                    register={register}
                  />
                  <div className={style.border} />
                  <Input
                    type={'number'}
                    name={'maxApplied'}
                    register={register}
                    placeholder={'100'}
                  />
                </div>
              </div>
              <div>
                <Selection
                  id="Select"
                  name="gender"
                  label="Gender"
                  setValue={setValue}
                  placeholder="Select"
                  options={genderOptions}
                  value={watch('gender')}
                />
              </div>
              <div>
                <Selection
                  id="Select"
                  menuWidth="200px"
                  name="availability"
                  setValue={setValue}
                  placeholder="Select"
                  label={'Availability'}
                  options={availabilityOptions}
                  value={watch('availability')}
                />
              </div>
              <div>
                <Input
                  type={'date'}
                  name="createdAt"
                  register={register}
                  placeholder={'Select'}
                  label={'Profile Creation Date'}
                />
              </div>
              <div>
                <Input
                  type={'date'}
                  name="lastActive"
                  register={register}
                  label={'Last Active Date'}
                />
              </div>
            </div>
            <div className={style.btnDiv}>
              <Button
                text={'Reset'}
                type={'button'}
                btnClass={style.btn}
                isLoading={btnLoader}
                loaderClass={style.loaderClass}
                handleClick={() => {
                  setLoader(true);
                  reset();
                  setFilterCount();
                  setBtnLoader(true);
                  setValue('gender', null);
                  setValue('location', null);
                  setValue('availability', null);
                  getCandidates();
                }}
              />
              <Button
                text={'Apply'}
                type={'submit'}
                isLoading={isSubmitting}
                handleClick={() => {
                  setLoader(true);
                  setFilterCount(watch('filterCount'));
                }}
              />
            </div>
          </div>
        </form>
      </div>
      {openFilter && (
        <div className={style.backdrop} onClick={() => setOpenFilter(false)} />
      )}
    </>
  );
};

export default Filters;
