import React from 'react';

import Modal from 'components/modal';
import Button from 'components/button';

import del from 'assets/del.svg';
import style from './del.module.scss';

const DelModal = ({ handleDelete, open, setOpen }) => {
  return (
    <div>
      <Modal
        open={open}
        handleClose={() => setOpen(false)}
        className={style.modal}
      >
        <div className={style.img}>
          <img src={del} alt="" />
        </div>
        <p>Are you sure you want to Delete? </p>
        <div className={style.btnDiv}>
          <Button
            text="Delete"
            handleClick={() => handleDelete()}
          />
          <Button
            text={'Cancel'}
            btnClass={style.btn}
            handleClick={() => setOpen(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DelModal;
