import React, { useEffect } from 'react';

import Input from 'components/input';
import Button from 'components/button';
import Selection from 'components/select';
import { statuses, titles, getFilterCount, types } from './helper';

import cross from 'assets/Close.svg';
import style from './filter.module.scss';

const Filters = ({
  watch,
  reset,
  getJobs,
  register,
  setValue,
  setLoader,
  btnLoader,
  openFilter,
  setBtnLoader,
  isSubmitting,
  handleSubmit,
  setOpenFilter,
  setFilterCount,
}) => {
  useEffect(() => {
    const count = getFilterCount(watch);
    setValue('filterCount', count);
  }, [watch()]);

  return (
    <>
      <div className={style.filter}>
        <div className={style.filterHeader}>
          <h1>Filters</h1>
          <img src={cross} alt="" onClick={() => setOpenFilter(false)} />
        </div>
        <form onSubmit={handleSubmit(getJobs)}>
          <div className={style.body}>
            <div className={style.inner}>
              <div>
                <Selection
                  id="Select"
                  name="jobStatus"
                  label="Job Status"
                  options={statuses}
                  setValue={setValue}
                  placeholder="Select"
                  value={watch('jobStatus')}
                />
              </div>
              <div className={style.label}>
                <label>Applicants</label>
                <div className={style.flex}>
                  <Input
                    type={'number'}
                    placeholder={'0'}
                    register={register}
                    name="minApplicants"
                  />
                  <div className={style.border} />
                  <Input
                    type={'number'}
                    register={register}
                    placeholder={'100'}
                    name={'maxApplicants'}
                  />
                </div>
              </div>
              <div>
                <Selection
                  id="Select"
                  options={types}
                  setValue={setValue}
                  placeholder="Select"
                  name="employmentType"
                  label={'Employment Type'}
                  value={watch('employmentType')}
                />
              </div>
              <div>
                <Selection
                  id="Select"
                  name="jobTitle"
                  options={titles}
                  menuWidth="200px"
                  setValue={setValue}
                  label={'Job Title'}
                  placeholder="Select"
                  value={watch('jobTitle')}
                />
              </div>
              <div>
                <Input
                  type={'date'}
                  name="postedAt"
                  register={register}
                  label={'Posted Date'}
                  placeholder={'Select'}
                />
              </div>
              <div>
                <Input
                  type={'date'}
                  name="startDate"
                  register={register}
                  label={'Starting Date'}
                />
              </div>
            </div>
            <div className={style.btnDiv}>
              <Button
                text={'Reset'}
                type={'button'}
                btnClass={style.btn}
                isLoading={btnLoader}
                loaderClass={style.loaderClass}
                handleClick={() => {
                  setLoader(true);
                  reset();
                  setFilterCount();
                  setBtnLoader(true);
                  setValue('jobTitle', null);
                  setValue('jobStatus', null);
                  setValue('employmentType', null);
                  getJobs();
                }}
              />
              <Button
                text={'Apply'}
                type={'submit'}
                isLoading={isSubmitting}
                handleClick={() => {
                  setLoader(true);
                  setFilterCount(watch('filterCount'));
                }}
              />
            </div>
          </div>
        </form>
      </div>
      {openFilter && (
        <div className={style.backdrop} onClick={() => setOpenFilter(false)} />
      )}
    </>
  );
};

export default Filters;
