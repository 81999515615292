import moment from 'moment';
import { Link } from 'react-router-dom';

import demo from 'assets/rider.svg';

import style from './listing.module.scss';

export const columns = [
  { key: 'jobName', name: 'Job Name', width: '250px' },
  {
    key: 'employerName',
    name: 'Employer Name',
    width: '200px',
  },
  { key: 'reporterName', name: 'Reporter Name', width: '200px' },
  { key: 'reason', name: 'Reason', width: '200px' },
  { key: 'description', name: 'Detailed Description', width: '200px' },
  { key: 'evidence', name: 'Evidence', width: '200px' },
  { key: 'createdAt', name: 'Reported Date', width: '120px' },
];

export const rows = ({ reports }) => {
  return (
    reports?.length > 0 &&
    reports.map(
      ({ jobInfo, reporterInfo, reason, description, evidence, createdAt }) => {
        return {
          jobName: {
            render: () => {
              return (
                <>
                  <Link to={`/jobs/details/${jobInfo?.id}`}>
                    <div className={style.title}>
                      <img src={jobInfo?.img || demo} alt="" />
                      <p>{jobInfo?.jobTitle || '-'}</p>
                    </div>
                  </Link>
                </>
              );
            },
          },
          employerName: jobInfo?.employerName || '-',
          reason: reason || '-',
          description: description || '-',
          createdAt: moment(createdAt).format('DD, MMM YY') || '-',
          evidence: evidence?.url
            ? {
                render: () => {
                  return (
                    <div className={style.position}>
                      <a target="_blank" rel="noreferrer" href={evidence?.url}>
                        {evidence?.name}
                      </a>
                    </div>
                  );
                },
              }
            : '-',
          reporterName: {
            render: () => {
              return (
                <div className={style.position}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={reporterInfo?.shareLink}
                  >
                    {reporterInfo?.fullName}
                  </a>
                </div>
              );
            },
          },
        };
      }
    )
  );
};
