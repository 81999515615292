import React from 'react';

import style from './table.module.scss';
import Loading from 'components/loading';

const Table = ({
  rows,
  trProp,
  loader,
  rowText,
  columns,
  minWidth,
  classBody,
  className,
  headingDiv,
  colomnProp,
  pageLoader,
  tableClass,
  tableHeight,
  headingText,
  containerRef,
  tableHeaderClass,
}) => {
  return (
    <>
      <div className={`${style.tableWrapper} ${tableHeight}`}>
        <div
          className={`${style.table} ${tableClass}`}
          style={{ minWidth: minWidth || '1200px' }}
        >
          <div
            className={`${style.thead}  ${tableHeaderClass}`}
            style={{ display: 'flex' }}
          >
            {columns.map((column, index) => (
              <div
                key={index}
                className={`${style.heading} ${headingDiv} ${column?.backClass}`}
                style={{
                  minWidth: column?.width ?? '250px',
                  textAlign: column?.alignText,
                  position: 'relative',
                  width: column?.maxWidth ?? '100%',
                }}
              >
                <p>
                  <span className={`${style.headingTitle} ${headingText}`}>
                    {column?.name}
                  </span>
                </p>
              </div>
            ))}
          </div>
          {!loader && rows?.length >= 1 ? (
            <>
              <div
                ref={containerRef}
                className={`${style.mainHeight} ${classBody} ${
                  pageLoader && style.pgLoader
                }`}
              >
                <div>
                  {rows?.map((row, index) => (
                    <div
                      className={`${style.tr} ${trProp}`}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        width: '100%',
                      }}
                      key={index}
                    >
                      {columns.map((column, colIndex) => (
                        <div
                          key={colIndex}
                          style={{
                            minWidth: column?.width ? column?.width : '250px',
                            display: 'block',
                            width: column?.maxWidth ? column?.maxWidth : '100%',
                          }}
                          className={style.fix}
                        >
                          <div
                            className={`${style.td}  ${className} ${colomnProp}`}
                          >
                            {row[column.key] && row[column.key].render
                              ? row[column.key].render()
                              : row[column.key]
                              ? row[column.key]
                              : '-'}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                  {pageLoader && rows?.length && (
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '20px',
                        width: 'calc(100vw - 300px)',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Loading />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${style.mainHeight} ${classBody}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 'calc(100vw - 300px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {loader ? (
                  <Loading />
                ) : (
                  !loader &&
                  !rows && <p className={style.noData}>No data Found</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
