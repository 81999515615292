import React from 'react';

import Sidebar from './sidebar';

import style from './layout.module.scss';

const Layout = ({ children }) => {
  return (
    <div className={style.layout}>
      <Sidebar />
      <div className={style.heightDiv}>{children}</div>
    </div>
  );
};

export default Layout;
