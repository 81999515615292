import { setUser } from 'redux-store';
import { apiRequest } from '../utils/helper';
import { setFormError } from '../helpers/hook-form-helper';
import createNotification from 'common/create-notification';

export const login = async ({ data, navigate, dispatch, setError }) => {
  const res = await apiRequest({
    body: data,
    type: 'post',
    path: '/auth/login',
  });
  if (res?.status === 200) {
    dispatch(
      setUser({ user: res?.data?.user, token: res?.headers?.authorization })
    );
    createNotification('success', res?.data?.msg);
    navigate('/');
  } else {
    setFormError(res, setError);
  }
};

export const deleteProfile = async ({ data, getCandidates }) => {
  const res = await apiRequest({
    type: 'post',
    path: '/auth/delete-profile',
    body: data,
  });
  if (res.status === 200) {
    getCandidates && getCandidates();
  }
};
