import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Protected = ({ element }) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.app.token);
  
  useEffect(() => {
    if (!token || token === "null") {
      navigate('/login');
    }
  }, [token, navigate]);

  return <>{element}</>;
};
