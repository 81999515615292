import React, { useEffect, useState } from 'react';

import Tabs from 'components/tabs';
import Layout from 'components/layout';
import CancelRequests from './cancel-requests';

import style from './subscription.module.scss';

const Subscriptions = () => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    const handlePopState = () => {
      const currentURL = window.location.href;
      if (currentURL.endsWith('/list')) {
        setActive(1);
      } else if (currentURL.endsWith('/cancel-requests')) {
        setActive(2);
      } else {
        setActive(0);
      }
    };
    handlePopState();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleTabChange = (index) => {
    setActive(index);
    if (index === 1) {
      window.history.pushState(null, '', `/subscriptions/list`);
    } else if (index === 2) {
      window.history.pushState(null, '', `/subscriptions/cancel-requests`);
    } else {
      window.history.pushState(null, '', `/subscriptions/overview`);
    }
  };

  return (
    <>
      <Layout>
        <div className={style.mainClass}>
          <h1 className={style.h1}>Jobs</h1>
          <div className={style.tabClass}>
            <Tabs
              tabs={tabs}
              active={active}
              setActive={handleTabChange}
              children={tabs[active].children}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Subscriptions;

const tabs = [
  { name: 'Overview', children: <div>Overview</div> },
  {
    name: 'Subscriptions',
    children: <div>Subscriptions</div>,
  },
  { name: 'Cancel Requests', children: <CancelRequests /> },
];
