import { createSlice } from '@reduxjs/toolkit';

import { isWindowDefined } from 'utils/helper';

const initialState = {
  user: {},
  token: (isWindowDefined() && localStorage.getItem('token')) || '',
  appLoader: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser(state, { payload: { user, token } }) {
      const currentToken = localStorage.getItem('token');
      localStorage.setItem('token', token || currentToken);
      state.user = user;
      state.token = token || currentToken;
      state.appLoader = false;
    },
    setLogout(state, { payload = '' }) {
      localStorage.setItem('token', payload);
      state.user = {};
      state.token = payload;
      state.appLoader = false;
    },
    setAppLoader(state, { payload = false }) {
      state.appLoader = payload;
    },
  },
});
