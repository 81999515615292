import Tags from 'components/tags';

import menu from 'assets/menu.svg';
import link from 'assets/link.svg';
import video from 'assets/video.svg';

import style from './applicant.module.scss';

export const cardData = (data) => [
  {
    heading: data?.count,
    title: 'Total Applicants',
  },
  {
    heading: data?.shortlisted,
    title: 'Shortlisted',
  },
  {
    heading: data?.notShortlisted,
    title: 'Not Shortlisted',
  },
];

export const onSearch = ({ search, data, setData }) => {
  const filteredData = data?.candidates?.filter((candidate) => {
    return Object.values(candidate).some((fieldValue) => {
      if (Array.isArray(fieldValue)) {
        return fieldValue.some((value) =>
          value.toLowerCase().includes(search.toLowerCase())
        );
      }
      return String(fieldValue).toLowerCase().includes(search.toLowerCase());
    });
  });

  setData((prev) => ({ ...prev, candidates: filteredData }));
};

export const columns = [
  { key: 'candidateName', name: 'Candidate Name', width: '250px' },
  { key: 'availability', name: 'Availability', width: '150px' },
  { key: 'status', name: 'Status', width: '150px' },
  { key: 'location', name: 'Location', width: '200px' },
  { key: 'videoInterview', name: 'Video Interview', width: '90px' },
  { key: 'applyDate', name: 'Applied Date', width: '120px' },
  { key: 'actions', name: 'Actions', width: '80px' },
];

export const sortOptions = [
  'Candidate Name',
  'Availability',
  'Status',
  'Location',
  'Video Interview',
  'Applied Date',
];

export const rows = ({
  data,
  popUp,
  setOpen,
  setPopUp,
  applicationId,
  setApplicationId,
}) => {
  return (
    data?.candidates?.length > 0 &&
    data?.candidates?.map((row) => {
      const bgColor = row?.status === 'Shortlisted' ? '#57b894' : '#FF5A5A';

      return {
        candidateName: {
          render: () => {
            return (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.yehaww.com/user-profile?id=${row?.userId}`}
                >
                  <div className={style.title}>
                    <img src={row?.img} alt="" />
                    <p>{row?.candidateName}</p>
                    <img src={link} alt="" />
                  </div>
                </a>
              </>
            );
          },
        },
        availability: row?.availability,
        status: {
          render: () => {
            return (
              <>
                <Tags text={row?.status} bgColor={bgColor} />
              </>
            );
          },
        },
        location: row?.location?.join(', '),
        videoInterview: {
          render: () => {
            return (
              <>
                <div className={style.title}>
                  <p>{row?.videoInterview}</p>
                  {row?.interviewVideoUrl && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={row?.interviewVideoUrl}
                    >
                      <img src={video} alt="" />
                    </a>
                  )}
                </div>
              </>
            );
          },
        },
        applyDate: row?.dateApplied,

        actions: {
          render: () => {
            return (
              <div className={style.position}>
                <img
                  alt=""
                  src={menu}
                  onClick={() => {
                    setPopUp(true);
                    setApplicationId(row?._id);
                  }}
                />
                {popUp && row?._id === applicationId && (
                  <div className={style.menu}>
                    <p>Edit</p>
                    <p
                      onClick={() => {
                        setOpen(true);
                        setPopUp(false);
                      }}
                    >
                      Remove
                    </p>
                  </div>
                )}
                {popUp && row?._id === applicationId && (
                  <div
                    className={style.backdrop}
                    onClick={() => {
                      setPopUp(false);
                      setApplicationId(null);
                    }}
                  />
                )}
              </div>
            );
          },
        },
      };
    })
  );
};
