import { Link } from 'react-router-dom';

import Toggle from 'components/toggle';
import { editCandidateSetting } from 'api-services/candidates';

import menu from 'assets/menu.svg';
import demo from 'assets/rider.svg';

import style from './listing.module.scss';

export const columns = [
  { key: 'candidateName', name: 'Candidate Name', width: '250px' },
  {
    key: 'profileCompletionRate',
    name: 'Profile Completion Rate',
    width: '150px',
  },
  { key: 'gender', name: 'Gender', width: '150px' },
  { key: 'jobsApplied', name: 'Jobs Applied', width: '200px' },
  { key: 'location', name: 'Location', width: '90px' },
  { key: 'availability', name: 'Availability', width: '180px' },
  { key: 'email', name: 'Email', width: '280px' },
  { key: 'lastActive', name: 'Last Active', width: '120px' },
  { key: 'profileCreatedDate', name: 'Profile Created Date', width: '170px' },
  { key: 'actions', name: 'Actions', width: '80px' },
];

export const sortOptions = [
  'Candidate Name',
  'Completion Rate',
  'Gender',
  'Jobs Applied',
  'Location',
  'Availability',
  'Email',
  'Last Active',
  'Created Date',
];

export const onSearch = ({ value, candidates, setCandidates }) => {
  const filteredData = candidates?.filter((candidate) => {
    return Object.values(candidate).some((fieldValue) => {
      if (Array.isArray(fieldValue)) {
        return fieldValue.some((value) =>
          value.toLowerCase().includes(value.toLowerCase())
        );
      }
      return String(fieldValue).toLowerCase().includes(value.toLowerCase());
    });
  });
  setCandidates(filteredData);
};

export const rows = ({
  candidates,
  popUp,
  setOpen,
  setPopUp,
  selectedId,
  setSelectedId,
}) => {
  return (
    candidates?.length > 0 &&
    candidates.map((row) => {
      return {
        candidateName: {
          render: () => {
            return (
              <>
                <Link to={`/candidates/details/${row?.userId}/applications`}>
                  <div className={style.title}>
                    <img src={row?.profilePic || demo} alt="" />
                    <p>{row?.candidateName || '-'}</p>
                  </div>
                </Link>
              </>
            );
          },
        },
        profileCompletionRate: Number(row?.profileCompletion) || '0',
        gender: row?.gender,
        jobsApplied: row?.jobsApplied || '0',
        location: row?.location || '-',
        availability: row?.availability || '-',
        email: row?.email || '-',
        lastActive: row?.lastActive || '-',
        profileCreatedDate: row?.createdAt || '-',
        actions: {
          render: () => {
            return (
              <div className={style.position}>
                <img
                  alt=""
                  src={menu}
                  onClick={() => {
                    setPopUp(!popUp);
                    setSelectedId(row?.userId);
                  }}
                />
                {popUp && row?.userId === selectedId && (
                  <div className={style.menu}>
                    <p className={style.toggle}>
                      Visible To Employers
                      <div>
                        <Toggle
                          name="profilePublicView"
                          defaultChecked={row?.profilePublicView}
                          handleChange={async (e) =>
                            await editCandidateSetting({
                              body: {
                                id: selectedId,
                                profilePublicView: e.target.checked,
                              },
                            })
                          }
                        />
                      </div>
                    </p>
                    <p className={style.toggle}>
                      Job Alerts
                      <Toggle
                        name="emailAlerts"
                        defaultChecked={row?.emailAlerts}
                        handleChange={async (e) =>
                          await editCandidateSetting({
                            body: {
                              id: selectedId,
                              emailAlerts: e.target.checked,
                            },
                          })
                        }
                      />
                    </p>
                    <p>Edit</p>
                    <p
                      onClick={() => {
                        setOpen(true);
                        setPopUp(false);
                      }}
                    >
                      Delete
                    </p>
                  </div>
                )}
                {popUp && row?.userId === selectedId && (
                  <div
                    className={style.backdrop}
                    onClick={() => setSelectedId(null)}
                  />
                )}
              </div>
            );
          },
        },
      };
    })
  );
};
