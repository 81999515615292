import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { setLogout } from 'redux-store';

import job from 'assets/jobs.svg';
import log from 'assets/logout.svg';
import logo from 'assets/white-logo.svg';
import setting from 'assets/setting.svg';
import user from 'assets/user-circle.svg';
import employee from 'assets/employee.svg';
import alert from 'assets/exclamation.svg';
import dashboard from 'assets/dashboard.svg';
import candidate from 'assets/candidate.svg';
import subscription from 'assets/subscription.svg';

import style from './sidebar.module.scss';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <div className={style.sidebar}>
      <div className={style.top}>
        <img src={logo} alt="" className={style.logo} />
        <div className={style.border} />
        <div className={style.userDiv}>
          <img src={user} alt="" />
          <div>
            <h6>Francois Soetewey</h6>
            <p>Admin</p>
          </div>
        </div>
        <div className={style.links}>
          {links?.map((ele, index) => (
            <Link to={ele.path}>
              <div
                key={index}
                className={style.link}
                style={{
                  backgroundColor:
                    pathname === ele.path
                      ? 'rgba(255, 255, 255, 0.10)'
                      : 'transparent',
                }}
              >
                <img src={ele.icon} alt="" />
                <p>{ele.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className={style.bottom}>
        <Link to="/login">
          <div className={style.link}>
            <img src={log} alt="" />
            <p onClick={() => dispatch(setLogout())}>Logout</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;

const links = [
  {
    path: '/',
    icon: dashboard,
    title: 'Dashboard',
  },
  {
    path: '/jobs/job-listing',
    icon: job,
    title: 'Jobs',
  },
  {
    path: '/candidates/candidate-listing',
    icon: candidate,
    title: 'Candidates',
  },
  {
    path: '#',
    icon: employee,
    title: 'Employers',
  },
  {
    icon: alert,
    path: '/job-reported',
    title: 'Jobs Reported',
  },
  {
    icon: subscription,
    title: 'Subscriptions',
    path: '/subscriptions/cancel-requests',
  },
  {
    path: '#',
    icon: setting,
    title: 'Settings',
  },
];
