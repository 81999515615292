import axios from "utils/axios";

export const isWindowDefined = () => (typeof window !== "undefined" ? true : false);

export const removeKeys = (data, keysToDelete, emptyString = false) => {
  for (const key in data) {
    keysToDelete.includes(key) && delete data[key];
    emptyString && data[key] === "" && delete data[key];
  }
  return data;
};

export const apiRequest = async ({ type, path, body, params, config, formData }) => {
  // axios.defaults.baseURL = process.env.API_BASE_URL;
  try {
    const res = await axios[type](
      path,
      formData
        ? body
        : {
            ...(body && body),
            ...(["get", "delete"].includes(type) && params && { params }),
          },
      { ...config, ...(["post", "put"].includes(type) && params && { params }) }
    );
    return res;
  } catch (err) {
    console.error(err);
    return err.response;
  }
};
