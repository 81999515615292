import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import Input from 'components/input';
import Table from 'components/table';
import Modal from 'components/modal';
import Button from 'components/button';
import Selection from 'components/select';

import { columns, rows } from './helper';
import { cancelRequest, getRequests } from 'api-services/subscriptions';

import menu from 'assets/menu.svg';
import icon from 'assets/filter.svg';
import cross from 'assets/red-cross.svg';

import style from './listing.module.scss';

const CancelRequests = () => {
  const containerRef = useRef();
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);

  const { watch, register, setValue } = useForm();

  const handleGetRequests = async () => {
    const res = await getRequests();
    if (res) {
      setCount(res?.count);
      setRequests(res?.requests);
    }
    setPageLoader(false);
  };

  const handleCancel = async () => {
    setOpen(false);
    const res = await cancelRequest({ id: open?._id });
    res && handleGetRequests();
  };

  const handleChange = async () => {
    const res = await getRequests();
    if (res) {
      setCount(res?.count);
      setRequests(res?.requests);
    }
    setPageLoader(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;

      if (scrollHeight - Math.ceil(scrollTop) <= clientHeight) {
        if (requests?.length < count) {
          setPageLoader(true);
        }
      }
    };

    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, requests, count]);

  useEffect(() => {
    handleGetRequests();
  }, []);

  return (
    <>
      <div className={style.main}>
        <div className={style.flex}>
          <div className={style.left}>
            <div className={style.filter}>
              <img src={icon} alt="" />
              <p>(0)</p>
              <p> Filters</p>
            </div>
            <Input
              name="search"
              search={true}
              register={register}
              handleChange={handleChange}
              placeholder="Type and Press Enter"
            />
          </div>
          <div className={style.right}>
            <p className={style.sort}>Sort by:</p>
            <div style={{ width: '150px' }}>
              <Selection
                id="Select"
                options={[]}
                name="sortBy"
                setValue={setValue}
                placeholder="Select"
                value={watch('sortBy')}
                handleChange={() => console.log('third')}
              />
            </div>
            <div className={style.ascending}>
              <div
                className={`${style.svg} ${style.tooltip}`}
                style={{
                  borderRadius: '5px 5px 0px 0px',
                  borderBottom: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={'#d6d6d6'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Ascending</span>
              </div>
              <div style={{ borderBottom: '1px solid #D6D6D6' }} />
              <div
                className={`${style.svg} ${style.tooltip}`}
                style={{
                  borderRadius: '0px 0px 5px 5px',
                  borderTop: 'none',
                  position: 'relative',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  style={{
                    transform: 'rotate(180deg)',
                  }}
                >
                  <path
                    d="M11.3027 6L6.22368 1L1.14463 6"
                    stroke={'#2A2E34'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className={style.tooltiptext}>Descending</span>
              </div>
            </div>
            <img src={menu} alt="" className={style.img} />
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Table
            rows={rows({ requests, setOpen })}
            columns={columns}
            minWidth={'2020px'}
            pageLoader={pageLoader}
            containerRef={containerRef}
            classBody={style.bodyClass}
            tableHeight={style.bodyClass}
            tableClass={style.tableClass}
          />
        </div>
      </div>
      <Modal
        open={open}
        className={style.modal}
        handleClose={() => setOpen(false)}
      >
        <div className={style.img}>
          <img src={cross} alt="" />
        </div>
        <p>Are you sure you want to cancel this user’s subscription? </p>
        <div className={style.btnDiv}>
          <Button
            text="Back"
            btnClass={style.btn}
            handleClick={() => setOpen(false)}
          />
          <Button
            btnClass={style.cancelBtn}
            text="Cancel Subscription"
            handleClick={() => handleCancel()}
          />
        </div>
      </Modal>
    </>
  );
};

export default CancelRequests;
