import moment from 'moment/moment';
import Tags from 'components/tags';

import demo from 'assets/rider.svg';
import cross from 'assets/red-cross.svg';
import style from './listing.module.scss';

export const columns = [
  { key: 'employerName', name: 'Employer Name', width: '250px' },
  { key: 'email', name: 'Email', width: '150px' },
  { key: 'status', name: 'Status', width: '150px', maxWidth: '140px' },
  { key: 'reason', name: 'Reason for Cancellation', width: '200px' },
  {
    key: 'feedback',
    name: 'Product Improvement',
    width: '230px',
    maxWidth: '230px',
  },
  {
    key: 'alternative',
    name: 'Alternative Services',
    width: '230px',
    maxWidth: '230px',
  },
  {
    key: 'requestDate',
    name: 'Request Date',
    width: '120px',
    maxWidth: '120px',
  },
  { key: 'actions', name: 'Actions', width: '67px', maxWidth: '67px' },
];

export const rows = ({ requests, setOpen }) => {
  return (
    requests?.length > 0 &&
    requests.map((row) => {
      const bgColor =
        row?.requestData?.status === 'Pending'
          ? '#FF5A5A'
          : row?.requestData?.status === 'Canceled' && '#8FD880';

      return {
        ...row,
        employerName: {
          render: () => {
            return (
              <>
                <div className={style.title}>
                  <img src={row?.img || demo} alt="" />
                  <p>{row?.employerName || '-'}</p>
                </div>
              </>
            );
          },
        },
        status: {
          render: () => {
            return (
              <>
                <Tags text={row?.requestData?.status} bgColor={bgColor} />
              </>
            );
          },
        },
        reason: row?.requestData?.reason || '-',
        feedback: row?.requestData?.feedback || '-',
        alternative: row?.requestData?.alternative || '-',
        requestDate:
          moment(row?.requestData?.date).format('DD, MMMM YY') || '-',
        actions: {
          render: () => {
            return (
              <div className={style.position} onClick={() => setOpen(row)}>
                <img alt="" src={cross} width={16} height={16} />
              </div>
            );
          },
        },
      };
    })
  );
};
